import { ReportTypes } from "./api.model";
import { APICore } from "./apiCore";

const api = new APICore();

const baseUrl = "";

export function getReportApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/projects`, payload.params);
}

export function getProjectByIDApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/projects`, payload.params);
}

export function getClientApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/clients`, payload.params);
}
export function getClientByIDApi(payload: { params: any }) {
  return api.get(`${baseUrl}/clients/${payload.params.id}`, {});
}
export function updateClientApi(payload: { params: any }) {
  const { id, ...filteredParams } = payload.params;
  return api.updatePatch(
    `${baseUrl}/clients/${payload.params.id}`,
    filteredParams
  );
}
export function createClientApi(payload: { params: Object }) {
  return api.create(`https://portalapiv2.vitale.digital/v1/clients`, payload.params);
}
export function deleteClientApi(payload: { params: Object }) {
  return api.delete(`${baseUrl}/clients`, payload.params);
}
export function getClientContactApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/contacts`, payload.params);
}
export function updateClientContactApi(payload: { params: any }) {
  const { firstName, lastName, phone, position, title, email } = payload.params;
  return api.updatePatch(`${baseUrl}/contacts/${payload.params.id}`, {
    firstName,
    lastName,
    phone,
    position,
    title,
    email,
  });
}
export function createClientContactApi(payload: { params: Object }) {
  return api.create(`${baseUrl}/contacts`, payload.params);
}
export function deleteClientContactApi(payload: { params: any }) {
  return api.delete(`${baseUrl}/contacts/${payload.params.id}`, {});
}
export function getClientAddressApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/addresses`, payload.params);
}
export function updateClientAddressApi(payload: { params: any }) {
  const { id, clientId, createdAt, updatedAt, ...filteredParams } =
    payload.params;
  return api.updatePatch(
    `${baseUrl}/addresses/${payload.params.id}`,
    filteredParams
  );
}
export function createClientAddressApi(payload: { params: any }) {
  const { id, createdAt, updatedAt, ...filteredParams } = payload.params;
  return api.create(`${baseUrl}/addresses`, filteredParams);
}
export function deleteClientAddressApi(payload: { params: any }) {
  return api.delete(`${baseUrl}/addresses/${payload.params.id}`, {});
}
export function getClientTasksApi(payload: { params: any }) {
  const { clientId } = payload.params;
  return api.get(`${baseUrl}/clients/${clientId}/onboarding-tasks`, {});
}
export function updateClientTasksApi(payload: { params: any }) {
  const { id, clientId, ...filteredParams } = payload.params;
  return api.updatePatch(
    `${baseUrl}/clients/${clientId}/onboarding-tasks/${id}`,
    filteredParams
  );
}
export function createClientTasksApi(payload: { params: any }) {
  const { createdAt, updatedAt, ...filteredParams } = payload.params;
  return api.create(
    `${baseUrl}/clients/${payload.params.clientId}/onboarding-tasks/setup`,
    {}
  );
}

export function deleteClientTasksApi(payload: { params: any }) {
  return api.delete(`${baseUrl}/addresses/${payload.params.id}`, {});
}

export function getAllDocumentsApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/files`, payload.params);
}
export function uploadDocumentsApi(payload: { params: Object }) {
  return api.create(`${baseUrl}/files`, payload.params);
}
export function deleteDocumentsApi(payload: { params: any }) {
  return api.delete(
    `${baseUrl}/files/${payload.params.id}?resourceId=${payload.params.resourceId}`,
    payload.params
  );
}

export function getCatogoryApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/categories`, payload.params);
}

export function getUsersApi(payload: { params: Object }) {
  return api.get(`${baseUrl}/user`, payload.params);
}

export function updateProjectApi(payload: { params: Object }) {
  return api.update(`${baseUrl}/projects`, payload.params);
}

export function createProjectApi(payload: { params: Object }) {
  return api.create(`${baseUrl}/projects`, payload.params);
}
export function deleteProjectApi(payload: { params: Object }) {
  return api.delete(`${baseUrl}/projects`, payload.params);
}
