import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { APICore } from "../../helpers/api/apiCore";
import { getReportApi } from "../../helpers";
import {
  getTechQuoteByIDAction,
  salesApiResponseError,
  salesApiResponseSuccess,
} from "./actions";
import { SalesActionTypes } from "./constants";
import {
  createClientAddressApi,
  createClientApi,
  createClientContactApi,
  createClientTasksApi,
  createProjectApi,
  deleteClientAddressApi,
  deleteClientApi,
  deleteClientContactApi,
  deleteClientTasksApi,
  deleteDocumentsApi,
  deleteProjectApi,
  getAllDocumentsApi,
  getCatogoryApi,
  getClientAddressApi,
  getClientApi,
  getClientByIDApi,
  getClientContactApi,
  getClientTasksApi,
  getProjectByIDApi,
  getUsersApi,
  updateClientAddressApi,
  updateClientApi,
  updateClientContactApi,
  updateClientTasksApi,
  updateProjectApi,
  uploadDocumentsApi,
} from "../../helpers/api/sales";
import { useNavigate } from "react-router-dom";
import {
  addDigitalQuoteApi,
  addRateCardsApi,
  addTechQuoteApi,
  deleteDigitalQuotesApi,
  deleteRateCardsApi,
  deleteTechQuotesApi,
  duplicateDigitalQuotesApi,
  duplicateTechQuotesApi,
  getDigitalQuotesApi,
  getRateCardsApi,
  getTechProductsApi,
  getTechQuotesApi,
  updateQuoteStatusApi,
  updateDigitalQuoteApi,
  updateRateCardsApi,
  updateTechQuoteApi,
  getTechQuotesApiClient,
} from "helpers/api/quotes";
import { AuthActionTypes } from "redux/auth/constants";
// import { create } from 'sortablejs';

const api = new APICore();

function* getReportSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getReportApi, { params });
    const projects = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.GET_PROJECTS, projects));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_PROJECTS, error));
  }
}

function* getProjectByIdSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getProjectByIDApi, { params });
    const project = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_PROJECTBYID, project)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_PROJECTBYID, error));
  }
}

function* getClientSaga({ payload: { params } }: any): SagaIterator {
  try {
    if (params?.id) {
      const response = yield call(getClientByIDApi, { params });
      const client = response.data;
      yield put(
        salesApiResponseSuccess(SalesActionTypes.GET_CLIENT_ID, client)
      );
    } else {
      const response = yield call(getClientApi, { params });
      const clients = response.data;
      yield put(salesApiResponseSuccess(SalesActionTypes.GET_CLIENT, clients));
    }
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_CLIENT, error));
  }
}

function* updateClientSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateClientApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.UPDATE_CLIENT, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.UPDATE_CLIENT, error));
  }
}

function* createClientSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(createClientApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.CREATE_CLIENT, data));
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.CREATE_CLIENT, error.response.data)
    );
  }
}

function* deleteClientSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteClientApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.DELETE_CLIENT, data));
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.DELETE_CLIENT, error.response.data)
    );
  }
}

function* getClientContactSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getClientContactApi, { params });
    const clients = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_CLIENT_CONTACT, clients)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.GET_CLIENT_CONTACT, error)
    );
  }
}

function* updateClientContactSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateClientContactApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.UPDATE_CLIENT_CONTACT, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.UPDATE_CLIENT_CONTACT, error)
    );
  }
}

function* createClientContactSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(createClientContactApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.CREATE_CLIENT_CONTACT, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(
        SalesActionTypes.CREATE_CLIENT_CONTACT,
        error.response.data
      )
    );
  }
}

function* deleteClientContactSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteClientContactApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DELETE_CLIENT_CONTACT, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(
        SalesActionTypes.DELETE_CLIENT_CONTACT,
        error.response.data
      )
    );
  }
}
function* getClientAddressSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getClientAddressApi, { params });
    const clients = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_CLIENT_ADDRESS, clients)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.GET_CLIENT_ADDRESS, error)
    );
  }
}

function* updateClientAddressSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateClientAddressApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.UPDATE_CLIENT_ADDRESS, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.UPDATE_CLIENT_ADDRESS, error)
    );
  }
}

function* createClientAddressSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(createClientAddressApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.CREATE_CLIENT_ADDRESS, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(
        SalesActionTypes.CREATE_CLIENT_ADDRESS,
        error.response.data
      )
    );
  }
}

function* deleteClientAddressSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteClientAddressApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DELETE_CLIENT_ADDRESS, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(
        SalesActionTypes.DELETE_CLIENT_ADDRESS,
        error.response.data
      )
    );
  }
}

function* getClientTasksSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getClientTasksApi, { params });
    const tasks = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_CLIENT_TASKS, tasks)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_CLIENT_TASKS, error));
  }
}

function* updateClientTasksSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateClientTasksApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.UPDATE_CLIENT_TASKS, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.UPDATE_CLIENT_TASKS, error)
    );
  }
}

function* createClientTasksSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(createClientTasksApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.CREATE_CLIENT_TASKS, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(
        SalesActionTypes.CREATE_CLIENT_TASKS,
        error.response.data
      )
    );
  }
}

function* deleteClientTasksSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteClientTasksApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DELETE_CLIENT_TASKS, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(
        SalesActionTypes.DELETE_CLIENT_TASKS,
        error.response.data
      )
    );
  }
}

function* getAllDocumentsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getAllDocumentsApi, { params });
    const responseData = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_DOCUMENTS, responseData)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_DOCUMENTS, error));
  }
}
function* uploadDocumentsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(uploadDocumentsApi, { params });
    const responseData = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.UPLOAD_DOCUMENTS, responseData)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.UPLOAD_DOCUMENTS, error));
  }
}
function* deleteDocumentsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteDocumentsApi, { params });
    const responseData = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DELETE_DOCUMENT, responseData)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.DELETE_DOCUMENT, error));
  }
}
function* getCategorySaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getCatogoryApi, { params });
    const project = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.GET_CATEGORY, project));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_CATEGORY, error));
  }
}
function* getUsersSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getUsersApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.GET_USERS, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_USERS, error));
  }
}

function* updateProjectSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateProjectApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.UPDATE_PROJECT, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.UPDATE_PROJECT, error));
  }
}

function* getTechProductsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getTechProductsApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_TECH_PRODUCTS, data)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_TECH_PRODUCTS, error));
  }
}

function* createProjectSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(createProjectApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.CREATE_PROJECT, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.CREATE_PROJECT, error));
  }
}
function* deleteProjectSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteProjectApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.DELETE_PROJECT, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.DELETE_PROJECT, error));
  }
}

function* addTechQuoteSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(addTechQuoteApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.ADD_TECH_QUOTE, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.ADD_TECH_QUOTE, error));
  }
}
function* updateTechQuoteSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateTechQuoteApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.UPDATE_TECH_QUOTE, data)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.UPDATE_TECH_QUOTE, error));
  }
}
function* sendForApprovalQuoteSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateQuoteStatusApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.SEND_FOR_APPROVAL, data)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.SEND_FOR_APPROVAL, error));
  }
}
function* getTechQuotesSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getTechQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(salesApiResponseSuccess(SalesActionTypes.GET_TECH_QUOTES, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_TECH_QUOTES, error));
  }
}
function* getTechQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getTechQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_TECH_QUOTE_BY_ID, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.GET_TECH_QUOTE_BY_ID, error)
    );
  }
}

function* getTechQuoteByClientSaga({ payload: { params } }: any): SagaIterator {
    try {
      const response = yield call(getTechQuotesApiClient, { params });
      const data = response.data;
      // NOTE - You can change this according to response format from your api
      yield put(
        salesApiResponseSuccess(SalesActionTypes.GET_TECH_QUOTE_BY_CLIENT, data)
      );
    } catch (error: any) {
      yield put(
        salesApiResponseError(SalesActionTypes.GET_TECH_QUOTE_BY_CLIENT, error)
      );
    }
  }

function* deleteTechQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteTechQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DELETE_TECH_QUOTE_BY_ID, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.DELETE_TECH_QUOTE_BY_ID, error)
    );
  }
}
function* duplicateTechQuoteByIDSaga({
  payload: { params },
}: any): SagaIterator {
  try {
    const response = yield call(duplicateTechQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DUPLICATE_TECH_QUOTE, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.DUPLICATE_TECH_QUOTE, error)
    );
  }
}
function* addDigitalQuoteSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(addDigitalQuoteApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.ADD_DIGITAL_QUOTE, data)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.ADD_DIGITAL_QUOTE, error));
  }
}
function* updateDigitalQuoteSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateDigitalQuoteApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.UPDATE_DIGITAL_QUOTE, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.UPDATE_DIGITAL_QUOTE, error)
    );
  }
}
function* getDigitalQuotesSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getDigitalQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_DIGITAL_QUOTES, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.GET_DIGITAL_QUOTES, error)
    );
  }
}
function* getDigitalQuoteByIDSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getDigitalQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID, error)
    );
  }
}
function* deleteDigitalQuoteByIDSaga({
  payload: { params },
}: any): SagaIterator {
  try {
    const response = yield call(deleteDigitalQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID, error)
    );
  }
}
function* duplicateDigitalQuoteSaga({
  payload: { params },
}: any): SagaIterator {
  try {
    const response = yield call(duplicateDigitalQuotesApi, { params });
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DUPLICATE_DIGITAL_QUOTE, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.DUPLICATE_DIGITAL_QUOTE, error)
    );
  }
}

function* getRateCardsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getRateCardsApi, { params });
    const data = response.data;
    yield put(salesApiResponseSuccess(SalesActionTypes.GET_RATE_CARDS, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.GET_RATE_CARDS, error));
  }
}
function* getRateCardsByIdSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(getRateCardsApi, { params });
    const data = response.data[0];
    yield put(
      salesApiResponseSuccess(SalesActionTypes.GET_RATE_CARD_BY_ID, data)
    );
  } catch (error: any) {
    yield put(
      salesApiResponseError(SalesActionTypes.GET_RATE_CARD_BY_ID, error)
    );
  }
}
function* addRateCardsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(addRateCardsApi, { params });
    const data = response.data;
    yield put(salesApiResponseSuccess(SalesActionTypes.ADD_RATE_CARDS, data));
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.ADD_RATE_CARDS, error));
  }
}
function* updateRateCardsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(updateRateCardsApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.UPDATE_RATE_CARDS, data)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.UPDATE_RATE_CARDS, error));
  }
}
function* deleteRateCardsSaga({ payload: { params } }: any): SagaIterator {
  try {
    const response = yield call(deleteRateCardsApi, { params });
    const data = response.data;
    yield put(
      salesApiResponseSuccess(SalesActionTypes.DELETE_RATE_CARDS, data)
    );
  } catch (error: any) {
    yield put(salesApiResponseError(SalesActionTypes.DELETE_RATE_CARDS, error));
  }
}

export function* watchGetProjectsSaga() {
  yield takeEvery(SalesActionTypes.GET_PROJECTS, getReportSaga);
}

export function* watchGetProjectByIdSaga() {
  yield takeEvery(SalesActionTypes.GET_PROJECTBYID, getProjectByIdSaga);
}
export function* watchgetClientSaga() {
  yield takeEvery(SalesActionTypes.GET_CLIENT, getClientSaga);
}

export function* watchupdateClientSaga() {
  yield takeEvery(SalesActionTypes.UPDATE_CLIENT, updateClientSaga);
}
export function* watchcreateClientSaga() {
  yield takeEvery(SalesActionTypes.CREATE_CLIENT, createClientSaga);
}

export function* watchdeleteClientSaga() {
  yield takeEvery(SalesActionTypes.DELETE_CLIENT, deleteClientSaga);
}
export function* watchgetClientContactSaga() {
  yield takeEvery(SalesActionTypes.GET_CLIENT_CONTACT, getClientContactSaga);
}

export function* watchupdateClientContactSaga() {
  yield takeEvery(
    SalesActionTypes.UPDATE_CLIENT_CONTACT,
    updateClientContactSaga
  );
}
export function* watchcreateClientContactSaga() {
  yield takeEvery(
    SalesActionTypes.CREATE_CLIENT_CONTACT,
    createClientContactSaga
  );
}

export function* watchdeleteClientContactSaga() {
  yield takeEvery(
    SalesActionTypes.DELETE_CLIENT_CONTACT,
    deleteClientContactSaga
  );
}
export function* watchgetClientAddressSaga() {
  yield takeEvery(SalesActionTypes.GET_CLIENT_ADDRESS, getClientAddressSaga);
}

export function* watchupdateClientAddressSaga() {
  yield takeEvery(
    SalesActionTypes.UPDATE_CLIENT_ADDRESS,
    updateClientAddressSaga
  );
}
export function* watchcreateClientAddressSaga() {
  yield takeEvery(
    SalesActionTypes.CREATE_CLIENT_ADDRESS,
    createClientAddressSaga
  );
}
export function* watchdeleteClientAddressSaga() {
  yield takeEvery(
    SalesActionTypes.DELETE_CLIENT_ADDRESS,
    deleteClientAddressSaga
  );
}

export function* watchGetClientTasksSaga() {
  yield takeEvery(SalesActionTypes.GET_CLIENT_TASKS, getClientTasksSaga);
}

export function* watchUpdateClientTasksSaga() {
  yield takeEvery(SalesActionTypes.UPDATE_CLIENT_TASKS, updateClientTasksSaga);
}
export function* watchCreateClientTasksSaga() {
  yield takeEvery(SalesActionTypes.CREATE_CLIENT_TASKS, createClientTasksSaga);
}
export function* watchDeleteClientTasksSaga() {
  yield takeEvery(SalesActionTypes.DELETE_CLIENT_TASKS, deleteClientTasksSaga);
}

export function* watchgetCategorySaga() {
  yield takeEvery(SalesActionTypes.GET_CATEGORY, getCategorySaga);
}
export function* watchGetAllDocumentsSaga() {
  yield takeEvery(SalesActionTypes.GET_DOCUMENTS, getAllDocumentsSaga);
}
export function* watchUploadDocumentsSaga() {
  yield takeEvery(SalesActionTypes.UPLOAD_DOCUMENTS, uploadDocumentsSaga);
}
export function* watchDeleteDocumentsSaga() {
  yield takeEvery(SalesActionTypes.DELETE_DOCUMENT, deleteDocumentsSaga);
}
export function* watchGetUsersSaga() {
  yield takeEvery(SalesActionTypes.GET_USERS, getUsersSaga);
}

export function* watchUpdateProjectSaga() {
  yield takeEvery(SalesActionTypes.UPDATE_PROJECT, updateProjectSaga);
}

export function* watchCreateProjectSaga() {
  yield takeEvery(SalesActionTypes.CREATE_PROJECT, createProjectSaga);
}
export function* watchDeleteProjectSaga() {
  yield takeEvery(SalesActionTypes.DELETE_PROJECT, deleteProjectSaga);
}
export function* watchgetTechProductsApiSaga() {
  yield takeEvery(SalesActionTypes.GET_TECH_PRODUCTS, getTechProductsSaga);
}
export function* watchAddTechQuoteSaga() {
  yield takeEvery(SalesActionTypes.ADD_TECH_QUOTE, addTechQuoteSaga);
}
export function* watchUpdateTechQuoteSaga() {
  yield takeEvery(SalesActionTypes.UPDATE_TECH_QUOTE, updateTechQuoteSaga);
}
export function* watchSendForApprovalTechQuoteSaga() {
  yield takeEvery(SalesActionTypes.SEND_FOR_APPROVAL, sendForApprovalQuoteSaga);
}
export function* watchgetTechQuotesSaga() {
  yield takeEvery(SalesActionTypes.GET_TECH_QUOTES, getTechQuotesSaga);
}
export function* watchgetTechQuoteByIDSaga() {
  yield takeEvery(SalesActionTypes.GET_TECH_QUOTE_BY_ID, getTechQuoteByIDSaga);
}
export function* watchdeleteTechQuoteByIDSaga() {
  yield takeEvery(
    SalesActionTypes.DELETE_TECH_QUOTE_BY_ID,
    deleteTechQuoteByIDSaga
  );
}

export function* watchgetTechQuoteByClientSaga() {
    yield takeEvery(SalesActionTypes.GET_TECH_QUOTE_BY_CLIENT, getTechQuoteByClientSaga);
  }

export function* watchduplicateTechQuoteByIDSaga() {
  yield takeEvery(
    SalesActionTypes.DUPLICATE_TECH_QUOTE,
    duplicateTechQuoteByIDSaga
  );
}
export function* watchAddDigitalQuoteSaga() {
  yield takeEvery(SalesActionTypes.ADD_DIGITAL_QUOTE, addDigitalQuoteSaga);
}
export function* watchUpdateDigitalQuoteSaga() {
  yield takeEvery(
    SalesActionTypes.UPDATE_DIGITAL_QUOTE,
    updateDigitalQuoteSaga
  );
}
export function* watchgetDigitalQuotesSaga() {
  yield takeEvery(SalesActionTypes.GET_DIGITAL_QUOTES, getDigitalQuotesSaga);
}
export function* watchgetDigitalQuoteByIDSaga() {
  yield takeEvery(
    SalesActionTypes.GET_DIGITAL_QUOTE_BY_ID,
    getDigitalQuoteByIDSaga
  );
}
export function* watchdeleteDigitalQuoteByIDSaga() {
  yield takeEvery(
    SalesActionTypes.DELETE_DIGITAL_QUOTE_BY_ID,
    deleteDigitalQuoteByIDSaga
  );
}
export function* watchduplicateDigitalQuoteSaga() {
  yield takeEvery(
    SalesActionTypes.DUPLICATE_DIGITAL_QUOTE,
    duplicateDigitalQuoteSaga
  );
}
export function* watchgetRateCardsSaga() {
  yield takeEvery(SalesActionTypes.GET_RATE_CARDS, getRateCardsSaga);
}
export function* watchgetRateCardsByIdSaga() {
  yield takeEvery(SalesActionTypes.GET_RATE_CARD_BY_ID, getRateCardsByIdSaga);
}
export function* watchaddRateCardsSaga() {
  yield takeEvery(SalesActionTypes.ADD_RATE_CARDS, addRateCardsSaga);
}
export function* watchupdateRateCardsSaga() {
  yield takeEvery(SalesActionTypes.UPDATE_RATE_CARDS, updateRateCardsSaga);
}
export function* watchdeleteRateCardsSaga() {
  yield takeEvery(SalesActionTypes.DELETE_RATE_CARDS, deleteRateCardsSaga);
}



function* salesSaga() {
  yield all([
    fork(watchGetProjectsSaga),
    fork(watchGetProjectByIdSaga),
    fork(watchgetCategorySaga),
    fork(watchGetUsersSaga),

    fork(watchUpdateProjectSaga),
    fork(watchCreateProjectSaga),
    fork(watchDeleteProjectSaga),

    fork(watchgetClientSaga),
    fork(watchupdateClientSaga),
    fork(watchcreateClientSaga),
    fork(watchdeleteClientSaga),

    fork(watchgetClientContactSaga),
    fork(watchupdateClientContactSaga),
    fork(watchcreateClientContactSaga),
    fork(watchdeleteClientContactSaga),

    fork(watchgetClientAddressSaga),
    fork(watchupdateClientAddressSaga),
    fork(watchcreateClientAddressSaga),
    fork(watchdeleteClientAddressSaga),

    fork(watchGetClientTasksSaga),
    fork(watchUpdateClientTasksSaga),
    fork(watchCreateClientTasksSaga),
    fork(watchDeleteClientTasksSaga),

    fork(watchGetAllDocumentsSaga),
    fork(watchUploadDocumentsSaga),
    fork(watchDeleteDocumentsSaga),
    fork(watchgetTechProductsApiSaga),
    fork(watchdeleteTechQuoteByIDSaga),
    fork(watchduplicateTechQuoteByIDSaga),
    fork(watchAddTechQuoteSaga),
    fork(watchgetTechQuotesSaga),
    fork(watchUpdateTechQuoteSaga),
    fork(watchSendForApprovalTechQuoteSaga),
    fork(watchgetTechQuoteByIDSaga),
    fork(watchgetTechQuoteByClientSaga),
    fork(watchAddDigitalQuoteSaga),
    fork(watchgetDigitalQuotesSaga),
    fork(watchgetDigitalQuoteByIDSaga),
    fork(watchdeleteDigitalQuoteByIDSaga),
    fork(watchduplicateDigitalQuoteSaga),
    fork(watchUpdateDigitalQuoteSaga),
    fork(watchgetRateCardsSaga),
    fork(watchgetRateCardsByIdSaga),
    fork(watchaddRateCardsSaga),
    fork(watchupdateRateCardsSaga),
    fork(watchdeleteRateCardsSaga),
  ]);
}

export default salesSaga;
